import { Box, Divider } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import type { RootState } from "@stores/store";
import { useHover } from "ahooks";
import {
  MENU_COLLECTION_KEY,
  MENU_MY_FAVORITE_KEY,
  MENU_NEW_PRODUCTS_KEY,
} from "constants/catalog";
import * as React from "react";
import { useSelector } from "react-redux";
import MenuDetail from "./menuDetail";
import TopMenuItem from "./topMenuItem";

const unfoldChildrenCatalog = (arr = [], catalog) => {
  for (let i = 0; i < catalog.children.length; i++) {
    const child = catalog.children[i];
    arr.push({
      id: child.id,
      slug: child.key,
      href: `/custom/${child.key}`,
      name: child.name,
    });
    if (child.children && child.children.length > 0) {
      for (let j = 0; j < child.children.length; j++) {
        arr = unfoldChildrenCatalog(arr, child.children[j]);
      }
    }
  }

  return arr;
};

const CategoryMenu = React.forwardRef(function CustomInput(
  {
    open,
    setOpen,
    position,
  }: {
    open: boolean;
    setOpen: (open: boolean) => void;
    position: { left: number; top: number };
  },
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const [activeKey, setActiveKey] = React.useState(MENU_NEW_PRODUCTS_KEY);
  const backgroundRef = React.useRef(null);

  const catalog = useSelector((store: RootState) => store.app.catalog);
  const collections = useSelector((store: RootState) => store.app.collection);
  const recommends = useSelector((store: RootState) => store.app.recommend);

  React.useEffect(() => {
    if (!open) {
      setActiveKey(MENU_NEW_PRODUCTS_KEY);
    }
  }, [open]);

  useHover(backgroundRef, {
    onEnter: function () {
      setOpen(false);
    },
  });

  const onHover = (key) => {
    if (key) {
      setActiveKey(key);
    }
  };

  const childrenMenuPropsMap = React.useMemo(() => {
    const map: { [key: string]: any } = {
      [MENU_COLLECTION_KEY]: {
        list:
          collections?.map((item) => ({
            href: `/custom/collections/${item.slug}`,
            name: item.name,
          })) || [],
        templates: recommends?.slice(0, 3) || [],
        title: "Recommended products",
      },
      [MENU_NEW_PRODUCTS_KEY]: {
        templates: recommends?.slice(0, 6) || [],
        title: "Recommended products",
        allHref: "/custom/collections/printy6-recommend",
      },
    };
    for (let i = 0; i < catalog.length; i++) {
      const item = catalog[i];
      const list = unfoldChildrenCatalog([], item);
      map[item.key] = {
        list,
        templates:
          recommends
            ?.filter((recommend) => {
              return (
                recommend.category_ids.findIndex((id) => id === item.id) !== -1
              );
            })
            .slice(0, 3) || [],
        title: `Recommended products`,
      };
    }
    return map;
  }, [collections, recommends, catalog]);

  const activeChildrenMenuProps = React.useMemo(() => {
    return childrenMenuPropsMap[activeKey] || {};
  }, [childrenMenuPropsMap, activeKey]);

  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      sx={{
        display: open ? "flex" : "none",
        position: "absolute",
        // border: "1px solid",
        width: "100vw",
        borderTop: "1px solid",
        pb: 1,
        px: {
          xs: 2,
          lg: 0,
        },
        overflow: "hidden",
        borderColor: grey[300],
        left: 0,
      }}
      style={{
        top: position.top,
      }}
    >
      <Box
        ref={backgroundRef}
        sx={{ width: "100%", height: "100%", position: "absolute", zIndex: 1 }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          maxWidth: (theme) => theme.breakpoints.values.md,
          // mx: "auto",
          borderRadius: "0  0 4px 4px",
          background: (theme) => theme.palette.common.white,
          boxShadow: (theme) => theme.shadows[4],
          zIndex: 2,
          position: "relative",
        }}
        style={
          matchesMd
            ? {
                left: 0,
              }
            : {
                left: position.left,
              }
        }
      >
        <Box
          sx={{
            py: 2,
            width: 300,
            borderRight: "1px solid",
            borderColor: (theme) => theme.palette.divider,
          }}
        >
          <TopMenuItem
            // icon={<AddAlarmIcon fontSize="small" />}
            name="Recommend"
            categoryKey={MENU_NEW_PRODUCTS_KEY}
            isActive={activeKey === MENU_NEW_PRODUCTS_KEY}
            onHover={onHover}
            href={`/custom/collections/${MENU_NEW_PRODUCTS_KEY}`}
          />
          <Divider sx={{ my: 2 }} />
          {catalog.map((item) => {
            return (
              <TopMenuItem
                key={item.id}
                // icon={catalogIconMap[item.key]}
                name={item.name}
                categoryKey={item.key}
                isActive={activeKey === item.key}
                onHover={onHover}
                href={`/custom/${item.key}`}
              />
            );
          })}
          <Divider sx={{ my: 2 }} />
          <TopMenuItem
            // icon={<WidgetsIcon fontSize="small" />}
            name="Collections"
            categoryKey={MENU_COLLECTION_KEY}
            isActive={activeKey === MENU_COLLECTION_KEY}
            onHover={onHover}
            href={`/custom`}
          />
          <TopMenuItem
            // icon={<FavoriteIcon fontSize="small" />}
            name="My favorites"
            categoryKey={MENU_MY_FAVORITE_KEY}
            isActive={activeKey === MENU_MY_FAVORITE_KEY}
            onHover={onHover}
            hasMore={false}
            href={`/custom/${MENU_MY_FAVORITE_KEY}`}
          />
        </Box>
        <MenuDetail {...activeChildrenMenuProps} />
      </Box>
    </Box>
  );
});

export default CategoryMenu;
