import * as React from "react";
import FooterBlock from "./footerBlock";

export default function Printy6(): React.ReactElement {
  return (
    <FooterBlock
      title="Printy6"
      list={[
        {
          title: "Terms of Service",
          href: "https://www.printy6.com/page/terms-of-service/",
        },
        {
          title: "Return & Refund Policy",
          href: "https://www.printy6.com/page/refund-and-return-policy/",
        },
        {
          title: "Privacy Policy",
          href: "https://www.printy6.com/page/privacy-policy/",
        },
        {
          title: "Shipping",
          href: "https://www.printy6.com/shipping",
        },
        {
          title: "Contact Us",
          href: "https://www.printy6.com/page/contact-us",
        },
        {
          title: "About Us",
          href: "https://www.printy6.com/page/about-us/",
        },
      ]}
    />
  );
}
