import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, Link } from "@mui/material";
import Typography from "@mui/material/Typography";

export default function LocalSetting() {
  return (
    <Box
      sx={(theme) => ({
        display: "inline-flex",
        [theme.breakpoints.down("sm")]: {
          justifyContent: "center",
        },
      })}
    >
      <HelpOutlineIcon />
      <Typography variant="body2" sx={{ ml: 0.5 }}>
        Need help? Send email to&nbsp;
      </Typography>
      <Link
        underline="always"
        href="mailto:support@printy6.com"
        color="inherit"
        sx={(theme) => ({
          [theme.breakpoints.down("sm")]: {
            display: "none",
          },
        })}
      >
        <Typography variant="subtitle2">support@printy6.com</Typography>
      </Link>
      <Link
        underline="always"
        href="mailto:support@printy6.com"
        color="inherit"
        sx={(theme) => ({
          [theme.breakpoints.up("sm")]: {
            display: "none",
          },
        })}
      >
        <Typography variant="subtitle2">us</Typography>
      </Link>
    </Box>
  );
}
