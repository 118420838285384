import staticImageLoader from "@lib/staticImageLoader";
import { Box } from "@mui/material";
import Image from "next/image";
import logo from "public/image/logo.svg";

const Logo = () => {
  return (
    <Box sx={{ width: 120 }}>
      <Image
        src={logo}
        alt="printy6 logo"
        width={120}
        height={40}
        loader={staticImageLoader}
      />
    </Box>
  );
};

export default Logo;
