import { Box, Grid } from "@mui/material";
import CopyRight from "./copyRight";
import Integration from "./integration";
import PaymentMethod from "./paymentMethod";
import Printy6 from "./printy6";
import Resources from "./resources";
import Start from "./start";
import Subscribe from "./subscribe";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        color: "#ffffff",
        backgroundColor: "#303238",
        textAlign: "center",
        p: 2,
      }}
      role="contentinfo"
    >
      <Subscribe />
      {/* <Policies /> */}
      <Box
        sx={{
          mt: 6,
          mb: 8,
          maxWidth: (theme) => theme.breakpoints.values.lg,
          mx: "auto",
        }}
      >
        <Grid container>
          <Grid item xs={6} sm={6} md={3}>
            <Integration />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Printy6 />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Start />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Resources />
          </Grid>
        </Grid>
      </Box>
      <PaymentMethod />
      <CopyRight />
    </Box>
  );
};

export default Footer;
