export default {
  title: "Printy6 - Print on Demand for Ecommerce and Artist",
  // canonical = 'https://www.printy6.com/',
  description:
    "400+ Print On Demand templates, Create products with your design and sell everywhere, we'll fulfill your orders for you. We will handle printing and shipping to your customers!",
  // openGraph: {
  //   type: "website",
  //   title: "Printy6 - Print on Demand for Ecommerce and Artist",
  //   locale: "en_IE",
  //   url: "https://www.printy6.com/",
  //   site_name: "Printy6",
  //   description:
  //     "Create products with your design and sell everywhere, we'll fulfill your orders for you. ✅ 200+ products. ✅ We will handle printing and shipping to your customers!",
  //   // images: [
  //   //   {
  //   //     url: "/_next/persistent/seo-og-image.jpg",
  //   //     width: 1200,
  //   //     height: 600,
  //   //     alt: "printy6 social media cover",
  //   //     type: "image/jpeg",
  //   //   },
  //   // ],
  // },
  facebook: {
    appId: "681451528956886",
  },
  twitter: {
    handle: "@handle",
    site: "@site",
    cardType: "summary_large_image",
  },
};
