import ChevronRightIcon from "@mui/icons-material/ChevronRightRounded";
import { Box, Link as MuiLink, Typography } from "@mui/material";
import { useHover } from "ahooks";
import Link from "next/link";
import * as React from "react";

export default function TopMenuItem({
  // icon,
  name,
  categoryKey,
  isActive = false,
  onHover,
  hasMore = true,
  href,
}) {
  const ref = React.useRef(null);
  const isHovering = useHover(ref);

  React.useEffect(() => {
    if (isHovering) {
      onHover(categoryKey);
    }
  }, [categoryKey, isHovering]);

  return (
    <Link href={href} passHref>
      <MuiLink color="inherit" underline="none">
        <Box
          ref={ref}
          sx={{
            display: "flex",
            pl: 2,
            py: 1.5,
            alignItems: "center",
            cursor: "pointer",
            bgcolor: isActive ? "grey.200" : "white",
          }}
        >
          {/* {icon} */}
          <Typography
            variant="body1"
            display="block"
            sx={{
              ml: 2,
              flex: 1,
              fontWeight: (theme) => theme.typography.fontWeightMedium,
            }}
          >
            {name}
          </Typography>
          {hasMore && (
            <Box sx={{ width: 30, display: "flex", alignItems: "center" }}>
              <ChevronRightIcon />
            </Box>
          )}
        </Box>
      </MuiLink>
    </Link>
  );
}
