import { Box } from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { initGA, logPageView } from "@utils/analytics";
import { useAtomValue } from "jotai";
import { useNotifier } from "lib/notification";
import { DefaultSeo } from "next-seo";
import React, { useEffect } from "react";
import SEO from "../../next-seo.config";
import AppReviewModal from "./appReviewModal";
import Footer from "./footer";
import Header from "./header";
import { topNotificationAtom } from "./header/topNotification";
import LocalSetting from "./localSetting";

interface ElevationScrollProps {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function ElevationScroll(props: ElevationScrollProps) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    id: "app-header",
    role: "navigation",
    position: "fixed",
    color: "inherit",
    elevation: trigger ? 4 : 0,
    sx: {
      borderBottom: trigger ? "0px" : "1px solid",
      borderColor: (theme) => theme.palette.divider,
    },
  });
}

const Layout = (props) => {
  const { defaultSeo = true, disableLocalSetting = false, ...other } = props;

  const isTopNotificationOpen = useAtomValue(topNotificationAtom);

  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }

    logPageView();

    if (window.__library__ != true) {
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 864796, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");

      window.__library__ = true;
    }
  }, []);

  useNotifier();

  return (
    <React.Fragment>
      {defaultSeo && <DefaultSeo {...SEO} />}
      <Header />
      <Box
        sx={{
          height: isTopNotificationOpen ? 118 : 78,
        }}
      />
      <Box id="content" role="main">
        {props.children}
      </Box>
      {!disableLocalSetting && (
        <Box
          sx={{
            p: 2,
            my: 2,
          }}
        >
          <LocalSetting />
        </Box>
      )}
      <Footer />
      <AppReviewModal />
    </React.Fragment>
  );
};

export default Layout;
