import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import { Box, Link as MuiLink, Typography } from "@mui/material";
import { logout } from "@services/app";
import { RootState } from "@stores/store";
import Link from "next/link";
import * as React from "react";
import { useSelector } from "react-redux";
import createStore from "teaful";
import MenuButton from "../menuButton";
import { Item, MenuItem } from "./menuItem";

type InitialValues = {
  isActive: boolean;
  secondary: null | string;
};

const initialValues: InitialValues = {
  isActive: false,
  secondary: null,
};

const index = {
  id: "index",
  name: "Home",
  is_full_path: false,
  href: "/",
};

const myProducts = {
  id: "my-products",
  name: "My Products",
  is_full_path: false,
  href: "/dashboard/products",
};

const orders = {
  id: "orders",
  name: "Orders",
  is_full_path: false,
  href: "/dashboard/orders",
};

const howItWorks = {
  id: "how-it-works",
  name: "How it works",
  href: "https://www.printy6.com/page/how-it-works/",
  is_full_path: true,
  full_name: "Bags",
};

const shipping = {
  id: "shipping",
  name: "Shipping",
  is_full_path: false,
  href: "/shipping",
  full_name: "Bags",
};

const faq = {
  id: "faq",
  name: "FAQ",
  is_full_path: true,
  href: "https://www.printy6.com/page/faq/",
};

export const { useStore: useMenuValues } = createStore(initialValues);

export const DrawerHeader = ({ children }) => {
  return (
    <Box
      sx={{
        height: 78,
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        borderBottom: "1px solid",
        borderBottomColor: (theme) => theme.palette.divider,
      }}
    >
      {children}
    </Box>
  );
};

export default function Menu({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement {
  const user = useSelector((store: RootState) => store.app.user);
  const cateogry = useSelector((store: RootState) => store.app.catalog);

  const productAndPricing = {
    id: "product-and-pricing",
    name: "Products & Pricing",
    all: "/custom/all",
    href: null,
    is_full_path: false,
    children: cateogry,
  };

  const menuList = [
    index,
    productAndPricing,
    myProducts,
    orders,
    howItWorks,
    shipping,
    faq,
  ];

  const handleLogout = () => {
    logout()
      .then(() => {
        window.location.assign("/");
      })
      .catch(() => {});
  };

  return (
    <Box
      sx={{
        overflow: "hidden",
      }}
    >
      <DrawerHeader>
        {user && <DashboardButton />}
        {!user && <LoginButton />}
        <Box sx={{ flexGrow: 1 }}></Box>
        <CloseButton onClose={onClose} />
      </DrawerHeader>
      <Box component="nav">
        {menuList.map((menu) => {
          if (menu.href) {
            return menu.is_full_path ? (
              <MuiLink
                href={menu.href}
                key={menu.id}
                underline="none"
                color="inherit"
              >
                <MenuItem menu={menu} />
              </MuiLink>
            ) : (
              <Link href={menu.href} key={menu.id} passHref>
                <MuiLink underline="none" color="inherit">
                  <MenuItem menu={menu} />
                </MuiLink>
              </Link>
            );
          } else {
            return <MenuItem menu={menu} key={menu.id} />;
          }
        })}
        {user && (
          <Item onClick={handleLogout}>
            <Typography>Log out</Typography>
          </Item>
        )}
      </Box>
    </Box>
  );
}

const CloseButton = ({ onClose }: { onClose: () => void }) => {
  return (
    <Box
      onClick={onClose}
      sx={{
        height: "100%",
        width: 78,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <MenuButton open={true} />
    </Box>
  );
};

const DashboardButton = ({}: {}) => {
  return (
    <Link href="/dashboard" passHref>
      <MuiLink color="inherit">
        <Box
          sx={{
            px: 2,
            height: "100%",
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DashboardRoundedIcon
            sx={{
              fontSize: 28,
              // display: "none",
            }}
          />
          <Typography
            variant="subtitle2"
            display="block"
            noWrap
            sx={{
              // textDecsoration: "underline",
              fontWeight: (theme) => theme.typography.fontWeightSemiBold,
              ml: 1,
            }}
          >
            Go to Dashboard
          </Typography>
        </Box>
      </MuiLink>
    </Link>
  );
};

const LoginButton = ({}: {}) => {
  return (
    <Box sx={{ display: "flex" }}>
      <Link href="/login" passHref>
        <MuiLink color="inherit">
          <Box
            sx={{
              px: 2,
              height: "100%",
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoginRoundedIcon
              sx={{
                fontSize: 24,
                // display: "none",
              }}
            />
            <Typography
              variant="body1"
              display="block"
              noWrap
              sx={{
                // textDecsoration: "underline",
                fontWeight: (theme) => theme.typography.fontWeightSemiBold,
                ml: 1,
              }}
            >
              Login
            </Typography>
          </Box>
        </MuiLink>
      </Link>
    </Box>
  );
};
