import { Box, Drawer } from "@mui/material";
import { useRouter } from "next/router";
import * as React from "react";
import Menu from "./menu";
import MenuButton from "./menuButton";

const MobileMenu = () => {
  const router = useRouter();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    onClose();
  }, [router.asPath]);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <MenuButton open={open} setOpen={setOpen} />
      <Drawer
        anchor="left"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            overflow: "hidden",
          },
        }}
      >
        <Box
          role="presentation"
          sx={{
            width: "350px",
          }}
        >
          <Menu onClose={onClose} />
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export default MobileMenu;
