import { Stack } from "@mui/material";
import AmericanExpressIcon from "public/image/card_brands/american-express-light-noborder.svg";
// import DinersIcon from "public/image/card_brands/diners-club-light-noborder.svg";
import DiscoverIcon from "public/image/card_brands/discover-light-noborder.svg";
// import JcbIcon from "public/image/card_brands/jcb-light-noborder.svg";
import MaestroIcon from "public/image/card_brands/maestro-light-noborder.svg";
import MastercardIcon from "public/image/card_brands/mastercard-light-noborder.svg";
import PaypalIcon from "public/image/card_brands/paypal-light-noborder.svg";
// import UnionPayIcon from "public/image/card_brands/union-pay-light-noborder.svg";
import staticImageLoader from "@lib/staticImageLoader";
import Image from "next/image";
import VisaIcon from "public/image/card_brands/visa-light-noborder.svg";

export default function PaymentMethod(props) {
  return (
    <Stack
      spacing={2}
      direction="row"
      id="footer-payment-methods"
      sx={{
        mx: "auto",
        justifyContent: "center",
        mt: 2,
      }}
    >
      <div>
        <Image
          height={24}
          src={VisaIcon}
          alt="visa"
          loader={staticImageLoader}
        />
      </div>
      <div>
        <Image
          height={24}
          src={MastercardIcon}
          alt="mastercard"
          loader={staticImageLoader}
        />
      </div>
      <div>
        <Image
          height={24}
          src={AmericanExpressIcon}
          alt="american express"
          loader={staticImageLoader}
        />
      </div>
      <div>
        <Image
          height={24}
          src={PaypalIcon}
          alt="paypal"
          loader={staticImageLoader}
        />
      </div>
      <div>
        <Image
          height={24}
          src={MaestroIcon}
          alt="maestro"
          loader={staticImageLoader}
        />
      </div>
      <div>
        <Image
          height={24}
          src={DiscoverIcon}
          alt="discover"
          loader={staticImageLoader}
        />
      </div>
      {/* <img height="24px" src={DinersIcon} alt="diners club" />
        <img height="24px" src={JcbIcon} alt="jcb" />
        <img height="24px" src={UnionPayIcon} alt="union pay" /> */}
    </Stack>
  );
}
