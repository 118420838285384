import { Box, Link as MuiLink, Stack, Typography } from "@mui/material";
import * as React from "react";

type Props = {
  title: string;
  list: {
    title: string;
    href: string;
  }[];
};

export default function FooterBlock({
  title,
  list,
}: Props): React.ReactElement {
  return (
    <Box>
      <Typography variant="subtitle1" component="div">
        {title}
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Stack spacing={0.5} direction="column">
          {list.map((item, index) => (
            <MuiLink
              key={index}
              href={item.href}
              variant="body2"
              underline="hover"
              sx={{
                // color: "#ffffff",
                color: "#c2c9d3",
              }}
            >
              {item.title}
            </MuiLink>
          ))}
        </Stack>
      </Box>
    </Box>
  );
}
