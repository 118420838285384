import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Box, Link as MuiLink } from "@mui/material";
import { useClickAway, useHover } from "ahooks";
import Link from "next/link";
import { useRouter } from "next/router";
import * as React from "react";
import CategoryMenu from "./categoryMenu";

const TitleLink = ({ href, title }) => {
  return (
    <Link href={href} passHref>
      <MuiLink
        underline="hover"
        noWrap
        variant="subtitle1"
        color="inherit"
        sx={{
          height: "100%",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          // textTransform: "uppercase",
          fontWeight: (theme) => theme.typography.fontWeightSemiBold,
          // fontWeight: (theme) => theme.typography.subtitle1.fontWeight,
        }}
      >
        {title}
      </MuiLink>
    </Link>
  );
};

const Menu = React.forwardRef(function CustomInput(
  {
    position,
  }: {
    position: { left: number; top: number };
  },
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const router = useRouter();
  const [categoryMenuOpen, setCategoryMenuOpen] = React.useState(false);
  const categoryMenuButtonRef = React.useRef(null);
  const categoryMenuRef = React.useRef(null);

  useHover(categoryMenuButtonRef, {
    onEnter: function () {
      setCategoryMenuOpen(true);
    },
    onLeave: function () {
      setCategoryMenuOpen(false);
    },
  });

  useClickAway(() => {
    setCategoryMenuOpen(false);
  }, [categoryMenuRef, categoryMenuButtonRef]);

  React.useEffect(() => {
    setCategoryMenuOpen(false);
  }, [router.asPath]);

  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        bgcolor: "white",
        zIndex: 2,
      }}
    >
      <Box
        ref={categoryMenuButtonRef}
        sx={{
          mx: 2,
          height: "100%",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
      >
        <TitleLink
          href="/custom/all"
          title={
            <>
              Products &amp; Pricing
              <KeyboardArrowDownRoundedIcon />
            </>
          }
        />
        <CategoryMenu
          position={position}
          ref={categoryMenuButtonRef}
          open={categoryMenuOpen}
          setOpen={setCategoryMenuOpen}
        />
      </Box>
      <Box
        sx={{
          mx: 2,
          height: "100%",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
      >
        <TitleLink href="/page/how-it-works/" title={"How it works"} />
      </Box>
      <Box
        sx={{
          mx: 2,
          height: "100%",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
      >
        <TitleLink href="/shipping" title={"Shipping"} />
      </Box>
      <Box
        sx={{
          mx: 2,
          height: "100%",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
      >
        <TitleLink href="/page/faq/" title={"FAQ"} />
      </Box>
    </Box>
  );
});

export default Menu;
