import { Badge, IconButton, Link as MuiLink } from "@mui/material";
import Cart from "components/svg/Cart";
import Link from "next/link";
import { useCartCount } from "services/cart";

export default function ShoppingCart() {
  const { data } = useCartCount();

  return (
    <Link href={`/shoppingCart`} as={`/cart`} passHref>
      <MuiLink id="go-to-cart">
        <Badge
          badgeContent={data?.count || 0}
          color="primary"
          anchorOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          overlap={"circular"}
        >
          <IconButton
            sx={{
              color: (theme) => theme.palette.text.primary,
            }}
          >
            <Cart style={{ fontSize: "24px" }} />
          </IconButton>
        </Badge>
      </MuiLink>
    </Link>
  );
}
