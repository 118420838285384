import staticImageLoader from "@lib/staticImageLoader";
import StarBorderIcon from "@mui/icons-material/StarBorderRounded";
import StarIcon from "@mui/icons-material/StarRounded";
import SwapHorizRoundedIcon from "@mui/icons-material/SwapHorizRounded";
import { Box, Dialog } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import type { RootState } from "@stores/store";
import { useTimeout } from "ahooks";
import Image from "next/image";
import LogoImage from "public/image/logo_icon.jpg";
import ShopifyImage from "public/image/platforms/shopify.svg";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const DEFAULT_DELAY = 60000;

const LogoImageWrap = styled("div")(({ theme }) => ({
  display: "flex",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  justifyContent: "center",
  boxShadow: "0 2px 4px 0 rgb(0 0 0 / 25%)",
  width: 80,
  height: 80,
  borderRadius: 8,
  overflow: "hidden",
}));

const AppReviewModal = () => {
  const app = useSelector((store: RootState) => store.app);

  const [delay, setDelay] = useState(null);
  const [open, setOpen] = useState(false);

  useTimeout(() => {
    setOpen(true);
    window.localStorage.setItem(
      "printy6_shopify_review",
      new Date().getTime().toString()
    );
  }, delay);

  useEffect(() => {
    if (app.shopify_review.demand_review) {
      let shopifyReviewTime = null;
      if (window && window.localStorage) {
        shopifyReviewTime = window.localStorage.getItem(
          "printy6_shopify_review"
        );
      }
      if (!shopifyReviewTime || !isToday(parseInt(shopifyReviewTime))) {
        setDelay(DEFAULT_DELAY);
      }
    }

    return () => {
      setDelay(null);
    };
  }, []);

  const isToday = (str) => {
    if (new Date(str).toDateString() === new Date().toDateString()) {
      //今天
      return true;
    } else if (new Date(str) < new Date()) {
      //之前
      return false;
    }
    return true;
  };

  const onClose = () => {
    setOpen(false);
  };

  const ratingClick = () => {
    window.open("https://apps.shopify.com/snaprinting#reviews");

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogContent>
        <Typography variant="h5" align="center">
          Review Printy6 App
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 40,
          }}
        >
          <LogoImageWrap>
            <Image src={LogoImage} loader={staticImageLoader} />
          </LogoImageWrap>
          &nbsp;
          <SwapHorizRoundedIcon fontSize="large" />
          &nbsp;
          <LogoImageWrap>
            <Image src={ShopifyImage} loader={staticImageLoader} />
          </LogoImageWrap>
        </Box>
        <Typography variant="body2" sx={{ mt: 1 }}>
          Having fun with our Shopify app? How about kindly leaving us a
          ⭐️⭐️⭐️⭐️⭐️ Review, which can definitely help us update better for
          you.
        </Typography>
        <Typography variant="body2">
          Your feedback & suggestions are highly appreciated! If adopted, we'll
          contact and offer you <b>15% off</b> on your first order!
        </Typography>
        <Box
          sx={{
            mx: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Rating
            defaultValue={5}
            precision={0.5}
            icon={<StarIcon style={{ fontSize: 36 }} />}
            emptyIcon={<StarBorderIcon style={{ fontSize: 36 }} />}
            onChange={ratingClick}
          />
          <Box
            sx={{
              mt: 2,
              border: "0px",
              background: "transparent",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            <Typography variant="body2" color="textSecondary" onClick={onClose}>
              Maybe Later
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AppReviewModal;
