import * as React from "react";
import FooterBlock from "./footerBlock";

export default function Resources(): React.ReactElement {
  return (
    <FooterBlock
      title="Resources"
      list={[
        {
          title: "Blog",
          href: "https://www.printy6.com/blog",
        },
        // {
        //   title: "Design guide",
        //   href: "/page/sell-on-etsy/",
        // },
        // {
        //   title: "Templates",
        //   href: "/page/sell-on-etsy/",
        // },
        {
          title: "How it works",
          href: "https://www.printy6.com/page/how-it-works/",
        },
        {
          title: "FAQ",
          href: "https://www.printy6.com/page/faq/",
        },
      ]}
    />
  );
}
