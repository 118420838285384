import { Box } from "@mui/material";
import clsx from "clsx";

const MenuButton = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen?: (status: boolean) => void;
}) => {
  const onClick = () => {
    setOpen && setOpen(!open);
  };

  return (
    <Box
      component="button"
      onClick={onClick}
      sx={(theme) => ({
        display: "flex",
        alignItems: "center",
        py: 0,
        px: 1,
        ml: -1,
        backgroundColor: "transparent",
        border: 0,
        margin: 0,
        cursor: "pointer",
        transition: theme.transitions.create(["opacity", "filter"], {
          duration: 150,
        }),
        font: "inherit",
        color: "inherit",
        textTransform: "none",
        overflow: "visible",
        WebkitTapHighlightColor: "transparent",
        "&:focus": {
          outline: "none",
        },
      })}
    >
      <Box
        component="span"
        sx={{
          position: "absolute",
          width: 1,
          height: 1,
          margin: -1,
          padding: 0,
          overflow: "hidden",
          clip: "rect(0,0,0,0)",
          border: 0,
        }}
      >
        Toggle navigation
      </Box>
      <Box
        component="span"
        className={clsx("hamburgerBox", {
          ["isActive"]: open,
        })}
        sx={{
          width: "24px",
          height: "20px",
          display: "inline-block",
          position: "relative",
          "&.isActive": {
            "& .hamburgerInner": {
              transform: "translate3d(0, 10px, 0) rotate(45deg)",
              "&:before": {
                transform: "rotate(-45deg) translate3d(-5.71429px, -6px, 0)",
                opacity: 0,
              },
              "&:after": {
                transform: "translate3d(0, -14px, 0) rotate(-90deg)",
              },
            },
          },
        }}
      >
        <Box
          component="span"
          className="hamburgerInner"
          sx={{
            display: "block",
            top: 0,
            width: 22,
            height: 2,
            backgroundColor: (theme) => theme.palette.text.primary,
            borderRadius: 1,
            position: "absolute",
            transition: (theme) =>
              theme.transitions.create("transform", {
                duration: 150,
              }),
            transitionProperty: "transform",
            transitionDuration: "0.15s",
            transitionTimingFunction: "ease",
            "&:before": {
              content: "''",
              display: "block",
              transitionProperty: "transform, opacity",
              transitionTimingFunction: "ease",
              transitionDuration: "0.15s",
              width: 16,
              height: 2,
              top: 7,
              backgroundColor: "#000",
              borderRadius: 1,
              position: "absolute",
            },
            "&:after": {
              content: "''",
              display: "block",
              top: 14,
              bottom: -7,
              width: 22,
              height: 2,
              backgroundColor: "#000",
              borderRadius: 1,
              position: "absolute",
              transition: (theme) =>
                theme.transitions.create("transform", {
                  duration: 150,
                }),
            },
          }}
        ></Box>
      </Box>
    </Box>
  );
};

export default MenuButton;
