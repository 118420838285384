import * as React from "react";
import FooterBlock from "./footerBlock";

export default function Start(): React.ReactElement {
  return (
    <FooterBlock
      title="Start"
      list={[
        // {
        //   title: "Stories",
        //   href: "/page/sell-on-etsy/",
        // },
        {
          title: "Custom t-shirts",
          href: "https://www.printy6.com/page/make-your-own-shirt/",
        },
        {
          title: "Custom shoes",
          href: "https://www.printy6.com/page/custom-shoes/",
        },
        // {
        //   title: "Custom products",
        //   href: "/page/sell-on-etsy/",
        // },
        {
          title: "Print on demand",
          href: "https://www.printy6.com/page/print-on-demand/",
        },
        // {
        //   title: "Bulk orders",
        //   href: "/page/sell-on-etsy/",
        // },
      ]}
    />
  );
}
