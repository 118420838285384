import { useNotifierAction } from "@lib/notification";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { Box, CircularProgress, Typography } from "@mui/material";
import trim from "lodash/trim";
import { useRouter } from "next/router";
import * as React from "react";
import { subscribeBlog } from "services/blog";

const Subscribe = () => {
  const [email, setEmail] = React.useState("");
  const [isCheck, setCheck] = React.useState(true);
  const [isSuccess, setSuccess] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useNotifierAction();
  const router = useRouter();

  const handleSubscribe = (ev) => {
    ev.preventDefault();
    const value = trim(email);
    if (value === "" || !isCheck) {
      return;
    }
    setLoading(true);
    subscribeBlog({
      email: value,
      from: router.asPath || null,
    })
      .then((res) => {
        setLoading(false);
        if (res && res.success) {
          setSuccess(true);
          return;
        }
        enqueueSnackbar({
          message: "Fail to subscribe.",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          },
        });
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar({
          message: "Fail to subscribe.",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          },
        });
      });
  };

  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          color: (theme) => theme.palette.common.white,
          mt: 4,
          mb: 2,
        }}
      >
        <Typography variant="h4" component="div">
          Subscribe for special offers & updates
        </Typography>
      </Box>
      <form onSubmit={handleSubscribe}>
        <Box
          sx={(theme) => ({
            maxWidth: theme.breakpoints.values.sm,
            mx: "auto",
            mt: 3,
            [theme.breakpoints.up("sm")]: {
              display: "flex",
            },
          })}
        >
          <Box
            component="span"
            sx={(theme) => ({
              display: "block",
              flexGrow: 1,
              mb: 4,
              [theme.breakpoints.up("sm")]: {
                mb: 0,
                mr: 1,
              },
            })}
          >
            <Box
              component="input"
              sx={(theme) => ({
                display: "block",
                width: "100%",
                height: 48,
                color: "#d6dadf",
                backgroundColor: "#303238",
                boxSizing: "border-box",
                border: "1px solid #d6dadf",
                fontSize: 16,
                px: 3,
                borderRadius: "24px",
                outline: "none",
                fontFamily: theme.typography.fontFamily,
                [theme.breakpoints.up("sm")]: {
                  borderRadius: "32px 0 0 32px",
                },
              })}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email address"
              readOnly={isLoading || isSuccess}
            />
          </Box>
          <Box
            component="button"
            type="submit"
            disabled={isLoading || isSuccess}
            sx={(theme) => ({
              m: 0,
              py: 0.5,
              px: 4,
              textAlign: "center",
              fontSize: 16,
              lineHeight: "18px",
              textDecoration: "none",
              cursor: "pointer",
              color: "#ffffff",
              textTransform: "uppercase",
              letterSpacing: "1px",
              borderColor: theme.palette.primary.main,
              height: "3rem",
              boxShadow: "none",
              position: "relative",
              borderRadius: "1.5rem",
              fontWeight: 600,
              background: theme.palette.primary.main,
              border: 0,
              fontFamily: theme.typography.fontFamily,
              outline: "none",
              [theme.breakpoints.up("sm")]: {
                borderRadius: "0 32px 32px 0",
              },
            })}
          >
            {isLoading && (
              <Box
                sx={{
                  width: 100,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress sx={{ color: "#ffffff" }} size={24} />
              </Box>
            )}
            {!isLoading &&
              (isSuccess ? (
                <Box
                  sx={{
                    width: 100,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CheckRoundedIcon fontSize="large" />
                </Box>
              ) : (
                <Box
                  sx={{
                    width: 100,
                  }}
                >
                  Subscribe
                </Box>
              ))}
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default Subscribe;
