import HomeIcon from "@mui/icons-material/HomeRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import LogoutIcon from "@mui/icons-material/LogoutRounded";
import { lighten, Theme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { logout } from "@services/app";
import type { RootState } from "@stores/store";
import clsx from "clsx";
import { useConfirm } from "material-ui-confirm";
import Link from "next/link";
import React from "react";
import { useSelector } from "react-redux";

const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

const stringAvatar = (user) => {
  const name = user.name || "Account";
  let nameArr = name.split(" ");

  if (user.avatar) {
    return {
      sx: {
        width: 28,
        height: 28,
      },
      children: (
        <img src={user.avatar} style={{ width: "100%" }} alt="user avatar" />
      ),
    };
  }

  return {
    sx: {
      backgroundColor: "rgba(0, 0, 0, 0.87)",
      width: 28,
      height: 28,
      fontSize: 13,
    },
    children: (
      <Typography
        sx={{
          textTransform: "uppercase",
        }}
        variant="body2"
      >
        {nameArr.length > 1
          ? `${nameArr[0][0]}${nameArr[1][0]}`
          : `${nameArr[0][0]}`}
      </Typography>
    ),
  };
};

export const AvatarMenu = (props) => {
  const {
    anchorOrigin = {
      vertical: "bottom",
      horizontal: "right",
    },
    transformOrigin = {
      vertical: "top",
      horizontal: "right",
    },
    menuProps = {},
  } = props;
  const user = useSelector((store: RootState) => store.app.user);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const theme: Theme = useTheme();
  const confirm = useConfirm();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    confirm({
      title: "Confirm",
      description: "Are you sure you want to logout ?",
      dialogProps: {
        maxWidth: "xs",
      },
    }).then(() => {
      logout()
        .then(() => {
          window.location.assign("/");
        })
        .catch(() => {});
    });
    handleClose();
  };

  return (
    <Box>
      <Box
        onClick={handleClick}
        sx={{
          display: "flex",
          alignItems: "center",
          py: 1,
          px: 1.5,
          cursor: "pointer",
          borderRadius: 8,
          backgroundColor: lighten(theme.palette.primary.main, 0.8),
          transition: theme.transitions.create(["background-color", "color"], {
            duration: 300,
          }),
          "&:hover": {
            background: theme.palette.primary.main,
            color: "#ffffff",
          },
          "&.active": {
            background: theme.palette.primary.main,
            color: "#ffffff",
          },
        }}
        className={clsx({
          ["active"]: open,
        })}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            m: "auto 0",
            width: "100%",
          }}
        >
          <Avatar {...stringAvatar(user)} />
          <Typography
            variant="subtitle2"
            noWrap={true}
            component="div"
            sx={{
              ml: 1,
              maxWidth: 150,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {user.name || "Account"}
          </Typography>
          &nbsp;
          <KeyboardArrowDownIcon sx={{ ml: "auto" }} />
        </Box>
      </Box>
      <Menu
        id="avatar-menu-poper"
        anchorEl={anchorEl}
        open={open}
        elevation={6}
        onClose={handleClose}
        autoFocus={false}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        variant="menu"
        {...menuProps}
      >
        <Link href="/dashboard/home" passHref>
          <MuiLink color="textPrimary" underline="none">
            <MenuItem sx={{ pt: 1.5, pb: 1.5 }}>
              <ListItemIcon>
                <HomeIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">Go to Dashboard</Typography>
              </ListItemText>
            </MenuItem>
          </MuiLink>
        </Link>
        <MenuItem sx={{ pt: 1.5, pb: 1.5 }} onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">Log out</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default AvatarMenu;
