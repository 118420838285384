import { Box, Divider, Grid, Link as MuiLink, Typography } from "@mui/material";
import cloneDeep from "lodash/cloneDeep";
import Link from "next/link";
import * as React from "react";
// import ProductCard from "./productCard";
import ProductCard from "containers/custom/productCard";

export default function MenuDetail({
  list = [],
  templates = [],
  title,
  allHref,
}: {
  list?: any[];
  templates?: any[];
  title?: string;
  allHref?: string;
}) {
  const [leftList, rightList] = React.useMemo(() => {
    const clone = cloneDeep(list);
    const half = Math.ceil(clone.length / 2);

    const firstHalf = clone.splice(0, half);
    const secondHalf = clone.splice(-half);

    return [firstHalf, secondHalf];
  }, [list]);

  return (
    <Box sx={{ flex: 1 }}>
      {(leftList.length > 0 || rightList.length > 0) && (
        <Box>
          <Grid container>
            <Grid item xs={6} sx={{ px: 4, py: 2 }}>
              {leftList?.map((item) => {
                return (
                  <Link key={item.name} href={item.href} passHref>
                    <MuiLink
                      color="inherit"
                      variant="body1"
                      underline="hover"
                      display="block"
                      sx={{
                        py: 1,
                      }}
                    >
                      {item.name}
                    </MuiLink>
                  </Link>
                );
              })}
            </Grid>
            <Grid item xs={6} sx={{ px: 4, py: 2 }}>
              {rightList?.map((item) => {
                return (
                  <Box
                    key={item.name}
                    sx={{
                      py: 1,
                    }}
                  >
                    <Link href={item.href} passHref>
                      <MuiLink
                        color="inherit"
                        variant="body1"
                        underline="hover"
                      >
                        {item.name}
                      </MuiLink>
                    </Link>
                  </Box>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      )}
      {(leftList.length > 0 || rightList.length > 0) &&
        templates.length > 0 && <Divider />}
      {title && templates.length > 0 && (
        <Box sx={{ py: 3, px: 4 }}>
          <Box sx={{ display: "flex", mb: 1.5, alignItems: "center" }}>
            <Typography
              variant="subtitle1"
              sx={{ lineHeight: "24px" }}
              component="div"
            >
              {title}
            </Typography>
            {allHref && (
              <Box sx={{ ml: "auto" }}>
                <Link href={allHref} passHref>
                  <MuiLink color="inherit" variant="subtitle2">
                    See all
                  </MuiLink>
                </Link>
              </Box>
            )}
          </Box>
          <Grid container spacing={2}>
            {templates.map((item) => {
              return (
                <Grid item xs={4} key={item.id}>
                  <Link href={`/design/${item.slug}`} passHref>
                    <MuiLink underline="none" color="inherit">
                      <ProductCard template={item} size="SMALL" />
                    </MuiLink>
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}
    </Box>
  );
}
