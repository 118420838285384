import * as React from "react";
import FooterBlock from "./footerBlock";

export default function Integration(): React.ReactElement {
  return (
    <FooterBlock
      title="Integration"
      list={[
        {
          title: "Shopify",
          href: "https://www.printy6.com/page/sell-on-shopify/",
        },
        {
          title: "Etsy",
          href: "https://www.printy6.com/page/sell-on-etsy/",
        },
        {
          title: "BigCommerce",
          href: "https://www.printy6.com/page/print-on-demand-for-bigcommerce/",
        },
        {
          title: "WooCommerce",
          href: "https://www.printy6.com/page/coming-soon/",
        },
        {
          title: "Amazon",
          href: "https://www.printy6.com/page/coming-soon/",
        },
        {
          title: "Printy6 API",
          href: "https://www.printy6.com/page/coming-soon/",
        },
      ]}
    />
  );
}
