import { Box, Typography } from "@mui/material";
import * as React from "react";

export default function CopyRight(): React.ReactElement {
  return (
    <Box
      sx={{
        color: "#8c95a5",
        my: 1,
        px: 2,
        textDecoration: "none",
        width: "100%",
        order: 6,
        mt: 3,
      }}
    >
      <Typography variant="body2" color="inherit">
        © Printy6. All Rights Reserved
      </Typography>
    </Box>
  );
}
