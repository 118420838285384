import ChevronLeft from "@mui/icons-material/ChevronLeftRounded";
import ChevronRight from "@mui/icons-material/ChevronRightRounded";
import { Box, Link as MuiLink, Typography } from "@mui/material";
import clsx from "clsx";
import Link from "next/link";
import React from "react";
import { DrawerHeader, useMenuValues } from ".";

export function Item({
  children,
  onClick,
  hasArrow = false,
}: {
  children: any;
  onClick?: () => void;
  hasArrow?: boolean;
}) {
  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        height: 56,
        mx: 1.5,
        borderBottom: "solid 1px",
        borderBottomColor: (theme) => theme.palette.divider,
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      {children}
      {hasArrow && (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            marginLeft: "auto",
            "& svg": {
              fontSize: 24,
            },
          }}
        >
          <ChevronRight />
        </Box>
      )}
    </Box>
  );
}

export function MenuItem(props) {
  const { menu } = props;
  const [secondary, setSecondary] = useMenuValues.secondary();

  const activeMenu = (index) => {
    setSecondary(index);
  };

  return (
    <React.Fragment>
      <Item
        hasArrow={menu.children && menu.children.length > 0}
        onClick={() => {
          activeMenu(menu.id);
        }}
      >
        <Typography>{menu.name}</Typography>
      </Item>
      {menu.children && menu.children.length > 0 ? (
        <Box
          sx={{
            position: "absolute",
            overflowY: "auto",
            top: 0,
            bottom: 0,
            right: "-100%",
            width: "100%",
            bgcolor: "white",
            transition: (theme) =>
              theme.transitions.create(["right"], {
                duration: 300,
              }),
            WebkitOverflowScrolling: "touch",
            "&.isActive": {
              right: 0,
              zIndex: 200,
            },
          }}
          className={clsx({
            ["isActive"]: secondary === menu.id,
          })}
        >
          <DrawerHeader>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                activeMenu(false);
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  width: 48,
                }}
              >
                <ChevronLeft sx={{ fontSize: 32 }} />
              </Box>
              <Typography
                variant="subtitle2"
                align="left"
                sx={{
                  flex: 1,
                  textDecoration: "underline",
                }}
              >
                Go Back
              </Typography>
            </Box>
          </DrawerHeader>
          {menu.all && (
            <Link href={menu.all} passHref>
              <MuiLink color="inherit" underline="none">
                <Item>
                  <Typography variant="body1">All Categories</Typography>
                </Item>
              </MuiLink>
            </Link>
          )}
          {menu.children.map((child, index) => (
            <React.Fragment key={index}>
              <Link key={child.id} href={`/custom/${child.key}`} passHref>
                <MuiLink color="inherit" underline="none">
                  <Item>
                    <Typography variant="body1">
                      <b>{child.name}</b>
                    </Typography>
                    {child.isNew ? (
                      <Box
                        sx={{
                          position: "relative",
                        }}
                      >
                        <Box
                          component="span"
                          sx={{
                            verticalAlign: "super",
                            position: "relative",
                            p: 0.5,
                            ml: 0.75,
                            borderRadius: 1,
                            color: "#ffffff",
                            fontSize: 9,
                            letterSpacing: ".8px",
                            textTransform: "uppercase",
                            bgcolor: "white",
                          }}
                        >
                          New
                        </Box>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Item>
                </MuiLink>
              </Link>
              {child?.children.map((secondChild, index) => (
                <Link key={index} href={`/custom/${secondChild.key}`} passHref>
                  <MuiLink color="inherit" underline="none">
                    <Item>
                      <Typography variant="body1">
                        &nbsp;&nbsp;&nbsp;&nbsp;{secondChild.name}
                      </Typography>
                      {secondChild.isNew ? (
                        <Box
                          sx={{
                            position: "relative",
                          }}
                        >
                          <Box
                            component="span"
                            sx={{
                              verticalAlign: "super",
                              position: "relative",
                              p: 0.5,
                              ml: 0.75,
                              borderRadius: 1,
                              color: "#ffffff",
                              fontSize: 9,
                              letterSpacing: ".8px",
                              textTransform: "uppercase",
                              bgcolor: "white",
                            }}
                          >
                            New
                          </Box>
                        </Box>
                      ) : (
                        ""
                      )}
                    </Item>
                  </MuiLink>
                </Link>
              ))}
            </React.Fragment>
          ))}
        </Box>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}
